<template>
  <div>
    <v-form ref="form" v-if="data">
      <v-row no-gutters>
        <v-col v-for="(feature, index) in data" :key="index" cols="6">
          <v-switch v-if="!feature.disabled" v-model="features[feature.value]" @change="emit">
            <template v-slot:label>
              <feature-icon v-model="feature.value"></feature-icon>
              {{ feature.text }}
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Feature from '../../services/feature';
import FeatureIcon from '../../components/Core/Features/FeatureIcon.vue';
const feature = new Feature();
export default {
  components: {
    FeatureIcon
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    access: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: null,
      features: {}
    };
  },
  created() {
    this.getFeatures();
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.features = { ...val };
        }
      },
      immediate: true
    }
  },
  methods: {
    async getFeatures() {
      this.data = feature.enabled(this.access);
    },
    emit() {
      this.$emit('input', this.features);
    }
  }
};
</script>

<style>
</style>
